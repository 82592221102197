define("nfe-customer-console-v2/routes/companies-v2/edit/nfe-dist", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Companyv2NfeDistRoute = (_dec = Ember.inject.service, (_class = (_temp = class Companyv2NfeDistRoute extends _authenticated.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "dfeDist", _descriptor, this);

      _defineProperty(this, "titleToken", "Parâmetros para consulta de DF-e distribuição");
    }

    async model({
      companyId
    }) {
      const apiKeyDescription = ["NFeDist (dfe.nfe.io)", "Nota Fiscal (api.nfe.io)"];
      const account = await this.sessionData.getCurrentAccount();
      let apiKey = account.apiKeys.find(key => apiKeyDescription.some(desc => desc === key.description)).key;
      let nfe = await this.dfeDist.getDfeConfig('nfe-dist', companyId, apiKey);

      if (nfe.requireActivation) {
        nfe.data = this.store.createRecord('nfe-dist', {
          automaticManifesting: this.store.createRecord('nfe-dist/automatic-manifesting')
        });
      }

      return {
        companyId,
        nfe: nfe.data
      };
    }

    async afterModel(model) {
      const account = await this.sessionData.getCurrentAccount();
      const isDevelopmentAccount = account.environment === "Development";

      if (isDevelopmentAccount) {
        model.isDevelopmentAccount = isDevelopmentAccount;
        model.nfe.set("startFromDate", new Date().toISOString());
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dfeDist", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Companyv2NfeDistRoute;
  ;
});