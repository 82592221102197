define("nfe-customer-console-v2/components/search-chips/component", ["exports", "nfe-customer-console-v2/helpers/cnpj-validator", "nfe-customer-console-v2/helpers/cpf-validator"], function (_exports, _cnpjValidator, _cpfValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service('router'),
    tagName: 'search-chips',
    classNames: ['layout-row', 'layout-align-start-center'],
    classNameBindings: ['smallBar:small-bar'],
    autofocus: false,
    useSearchTerm: false,
    debounceSearch: false,
    smallBar: false,
    searchTermProps: Ember.computed('searchTerm', function () {
      var ret = Ember.A();
      var term = this.searchTerm;

      if (term && term.length >= 3) {
        ret.pushObject({
          field: 'any',
          type: 'query',
          name: this.searchTermPlaceholder,
          value: this.searchTerm
        });
      }

      return ret;
    }),
    searchProps: Ember.A(),
    remainingSearchProps: Ember.computed('searchProps.length', function () {
      return this.allSearchProps.filter(source => {
        return !this.searchProps.any(function (item) {
          return source.field === item.field;
        });
      });
    }),
    autofocusWhenIsEnabled: Ember.observer('readOnly', function () {
      if (this.autofocus && this.readOnly === false) {
        Ember.run.later(this, function () {
          this.$().find("input")[0].focus();
        });
      }
    }),
    minDate: new Date('2017-01-02'),
    maxDate: new Date(),
    federalTaxNumberValidations: [{
      message: 'Insira um CNPJ ou CPF inválido',
      validate: inputValue => {
        return (0, _cnpjValidator.cnpjValidator)(inputValue) || (0, _cpfValidator.cpfValidator)(inputValue);
      }
    }],

    init() {
      this._super(...arguments); // must have line


      const validRoutes = ['companies-v2.consumer-invoices.index', 'companies-v2.product-invoices.index'];

      if (!validRoutes.includes(this.router.currentRouteName)) {
        if (!Ember.isEmpty(this.allSearchProps)) {
          this.set('searchProps', this.allSearchProps.filter(_ => {
            return Ember.isPresent(_.value);
          }));
        }
      } else {
        this.searchProps.clear();
      }
    },

    actions: {
      setSearchTerm(item) {
        this.set('searchTerm', item);
        this.onChanged(item);
      },

      unsetSearchTerm(item) {
        this.set('searchTerm', null);
        this.onChanged(null);
      },

      closePromptDialog(action, searchProp) {
        this.set('showPromptDialog', false);
        if (action == 'filterDate') searchProp.value = searchProp.date;
        if (action == 'filterCreatedDate') searchProp.value = searchProp.createdDate;
        if (action == 'filterIssuedDate') searchProp.value = searchProp.issuedDate;
        this.defaultFilter(searchProp);
      },

      addItem(item) {
        this.set('dialogSearchProp', item);
        if (item.field == "federalTaxNumber") this.set("customValidations", this.federalTaxNumberValidations);

        if (item.type == 'date' && item.field != "date-range" && item.field != "date-created-range" && item.field != "date-issued-range") {
          this.fastFilterDate(item);
        } else {
          this.set('showPromptDialog', true);
        }
      },

      removeItem(item) {
        if (item.value) delete item.value;
        this.searchProps.removeObject(item);
        this.onChanged(item, "remove");

        if (item.date) {
          Ember.set(item.date, "begin", new Date());
          Ember.set(item.date, "end", new Date());
        }

        if (item.createdDate) {
          Ember.set(item.createdDate, "begin", new Date());
          Ember.set(item.createdDate, "end", new Date());
        }
      }

    },

    defaultFilter(item) {
      this.searchProps.filter(prop => {
        if (prop.field === item.field) {
          this.searchProps.removeObject(prop);
        }
      });
      this.searchProps.pushObject(item);
      this.onChanged(item);
    },

    fastFilterDate(item) {
      this.searchProps.filter(prop => {
        if (prop.type === item.type) {
          this.searchProps.removeObject(prop);
        }
      });
      this.searchProps.pushObject(item);
      let date = {
        begin: new Date(),
        end: new Date()
      };
      if (item.field == "curWeek") date.begin.setDate(date.begin.getDate() - date.begin.getDay());
      if (item.field == "curMonth") date.begin.setDate(1);

      if (item.field == "lastMonth") {
        date.begin.setDate(0);
        date.begin.setDate(1);
        date.end.setDate(0);
      }

      if (item.field == "curYear") date.begin = new Date(date.begin.getFullYear(), 0, 1);
      Ember.set(item, "createdDate", date);
      Ember.set(item, "date", date);
      this.onChanged(item);
    },

    willDestroyElement() {
      this.searchProps.clear();
    }

  });

  _exports.default = _default;
});