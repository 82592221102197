define("nfe-customer-console-v2/components/address-form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    address: Ember.inject.service(),
    store: Ember.inject.service('store'),
    prefectures: Ember.inject.service(),
    model: null,
    prefecturesAvailable: null,
    states: null,
    cities: null,
    autoFillDisabled: false,
    selectedState: Ember.computed('model.state', function () {
      return this.model.state ? {
        code: this.model.state
      } : null;
    }),

    async init() {
      this._super(...arguments);

      let states = await this.address.getStates();
      let prefectures = await this.prefectures.getPrefectures();
      this.set('states', states);
      this.set('prefecturesAvailable', prefectures);

      if (this.model.state) {
        const state = this.get('model.state');
        let cities = await this.address.getCitiesByState(state);
        this.set('cities', cities);
      } else {
        this.set('model', this.store.createRecord('address'));
      }
    },

    autoFill: (0, _emberConcurrency.task)(function* () {
      if (this.model.validations.attrs.postalCode.isInvalid || this.autoFillDisabled) return this.model;
      const postalCode = this.model.postalCode.replace(/\D/, '');

      try {
        const address = yield this.address.getAddressByPostalCode(postalCode);
        if (!address) return;
        this.model.eachAttribute(name => {
          if (name != 'postalCode') this.set(`model.${name}`, address[name]);
        });
      } catch (error) {
        return;
      }
    }),
    getCities: Ember.observer('model.state', async function () {
      const state = this.model.state;
      if (Ember.isEmpty(state)) return;
      let isInCities = false;

      try {
        let cities = await this.address.getCitiesByState(state);
        this.set('cities', cities);
        let code = this.get('model.city.code') || 0;
        cities.forEach(city => {
          if (code === city.code.toString()) isInCities = true;
        });
        if (!isInCities) return this.set('model.city', cities[0]);
      } catch (error) {
        return this.set('model.city', cities[0]);
      }
    })
  });

  _exports.default = _default;
});