define("nfe-customer-console-v2/controllers/companies/edit/environment", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompanyEnvironmentController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('model'), _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)(function* (model) {
    const adapter = this.store.adapterFor('company');

    try {
      yield adapter.saveEnvironment(model.account.accountNumber, model.id, model.environment === "Development" ? "Production" : "Development");
      this.notify.success(`Ambiente alterado com sucesso!`);
      yield (0, _emberConcurrency.timeout)(750);
      location.reload();
    } catch (error) {
      this.notify.error(`Erro ao alterar ambiente!`);
    } finally {
      this.set("switchEnvironmentModal", false);
    }
  }), (_class = (_temp = class CompanyEnvironmentController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "company", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "saveEnvironment", _descriptor4, this);
    }

    get certificateAlert() {
      return this.get('model.isDevelopmentEnvironment') && (this.model.isCertificatePending || this.model.isCertificateExpired);
    }

    switchEnvironment() {
      if (this.certificateAlert) {
        this.set("showCertificateAlert", true);
      } else {
        this.set("switchEnvironmentModal", true);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "certificateAlert", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "certificateAlert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "switchEnvironment", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "switchEnvironment"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveEnvironment", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CompanyEnvironmentController;
  ;
});