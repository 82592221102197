define("nfe-customer-console-v2/controllers/companies-v2/product-invoices/index", ["exports", "nfe-customer-console-v2/models/enums", "ember-parachute", "moment", "ember-concurrency"], function (_exports, _enums, _emberParachute, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ProductInvoicesListQueryParams = void 0;
  const ProductInvoicesListQueryParams = new _emberParachute.default({
    environment: {
      as: 'environment',
      defaultValue: null,
      refresh: true,
      replace: true
    },
    limit: {
      as: "limit",
      defaultValue: 10,
      refresh: true,
      replace: true
    },
    startingAfter: {
      as: "startingAfter",
      defaultValue: null,
      refresh: true,
      replace: true
    },
    endingBefore: {
      as: "endingBefore",
      defaultValue: null,
      refresh: true,
      replace: true
    },
    page: {
      as: "page",
      defaultValue: 1,
      refresh: true,
      replace: true
    },
    elasticSearch: {
      as: "q",
      defaultValue: null,
      refresh: true,
      replace: true
    }
  });
  _exports.ProductInvoicesListQueryParams = ProductInvoicesListQueryParams;

  var _default = Ember.Controller.extend(ProductInvoicesListQueryParams.Mixin, {
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    productInvoice: Ember.inject.service(),
    export: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    exportTypes: _enums.default.exportNfeTypes,
    exportStatuses: _enums.default.exportNfeStatuses,
    exportStatusesByType: {
      "analytical-csv": _enums.default.exportNfeStatuses,
      "pdf": _enums.default.exportNfeStatuses.filter(({
        code
      }) => ["none", "issued"].includes(code)),
      "xml": _enums.default.exportNfeStatuses.filter(({
        code
      }) => ["none", "issued", "cancelled"].includes(code))
    },
    isExportInvalid: Ember.computed('exportFilters.{type,status,issueBegin,issueEnd}', function () {
      const filters = this.get('exportFilters');
      return Object.values(filters).some(Ember.isEmpty);
    }),
    perPageOptions: Ember.A([10, 20, 30, 40, 50]),
    queryParamsChanged: Ember.computed.or('queryParamsState.{environment,limit,startingAfter,endingBefore,elasticSearch}.changed'),
    direction: Ember.computed('endingBefore', 'statingAfter', function () {
      let direction = null;

      if (this.endingBefore) {
        direction = 'backward';
      } else if (this.endingBefore) {
        direction = 'forward';
      }

      return direction;
    }),
    invoice: null,
    tryCancelInvoiceModal: false,
    exportNotify: false,
    exportEmails: null,
    defaultFilterEnvironment: Ember.computed('environment', function () {
      const account = this.session.data.authenticated;
      const environment = Ember.isPresent(account.current_account_impersonate) ? account.current_account_impersonate.environment : account.current_account.environment;
      return environment == 'Development' ? 'Test' : environment;
    }),
    allSearchProps: Ember.computed('environment', function () {
      const lenvironment = this.environment;
      return Ember.A([{
        field: 'environment',
        name: 'environment',
        type: 'radio',
        values: _enums.default.accountEnvironments,
        value: _enums.default.accountEnvironments.filterBy('id', lenvironment).firstObject
      }, {
        field: 'invoiceId',
        name: 'companies.invoices.filter.invoiceId',
        type: 'text',
        queryName: 'id'
      }, {
        field: 'status',
        name: 'companies.invoices.filter.status',
        type: 'radio',
        values: _enums.default.nfeStatuses,
        queryName: 'status'
      }, {
        field: 'invoiceNumber',
        name: 'companies.invoices.filter.invoiceNumber',
        type: 'number',
        queryName: 'number'
      }, {
        field: 'federalTaxNumber',
        name: 'companies.invoices.filter.federalTaxNumber',
        type: 'number',
        mask: '(99.999.999/9999-99)|(999.999.999-99)',
        queryName: 'buyer.federalTaxNumber'
      }, {
        field: 'name',
        name: 'companies.invoices.filter.name',
        type: 'text',
        queryName: 'buyer.name'
      }, {
        field: 'email',
        name: 'companies.invoices.filter.email',
        type: 'text',
        queryName: 'buyer.email'
      }, {
        field: 'date-range',
        name: 'companies.invoices.filter.date',
        type: 'date',
        date: {
          begin: new Date(),
          end: new Date()
        },
        queryName: 'createdOn'
      }, {
        field: 'curWeek',
        name: 'companies.invoices.filter.curWeek',
        type: 'date',
        queryName: 'createdOn'
      }, {
        field: 'curMonth',
        name: 'companies.invoices.filter.curMonth',
        type: 'date',
        queryName: 'createdOn'
      }, {
        field: 'lastMonth',
        name: 'companies.invoices.filter.lastMonth',
        type: 'date',
        queryName: 'createdOn'
      }, {
        field: 'curYear',
        name: 'companies.invoices.filter.curYear',
        type: 'date',
        queryName: 'createdOn'
      }]);
    }),
    actions: {
      filter({
        type,
        field,
        value,
        queryName,
        date,
        createdOn
      }, action) {
        let queryParams = {};
        this.set('direction', null);

        if (action == "remove") {
          if (queryName) queryParams.elasticSearch = this.removeElasticQuery(queryName);
          if (field == "environment") queryParams.environment = null;
        } else {
          switch (type) {
            case "radio":
              if (field == "environment") queryParams.environment = value.id;
              if (field == "status") queryParams.elasticSearch = this.elasticQueryBuilder(queryName, value.id);
              break;

            case "number":
              if (queryName) queryParams.elasticSearch = this.elasticQueryBuilder(queryName, value);
              break;

            case "date":
              this.set('queryDate', date);
              let dateQuery = this.dateQueryBuilder(field, queryName, date);
              queryParams.elasticSearch = this.elasticQueryBuilder(queryName, dateQuery);
              break;

            default:
              queryParams.elasticSearch = this.elasticQueryBuilder(queryName, value);
          }
        } // set pagination to default values


        queryParams.startingAfter = null;
        queryParams.endingBefore = null;
        queryParams.page = 1;
        this.transitionToRoute('companies-v2.product-invoices', this.model.companyId, {
          queryParams
        });
      },

      changeExportType(type) {
        this.set('exportFilters.type', type);
        this.set('exportFilters.status', this.get('exportStatuses').find(({
          code
        }) => code == "none"));
      },

      mutPerPage(perPage) {
        this.set('limit', perPage);
        this.set('endingBefore', null);
        this.set('startingAfter', null);
        this.set('page', 1);
      },

      mutPage(action, data) {
        if (action === "increment") {
          let lastItem = data.lastObject;
          this.set('startingAfter', lastItem.id);
          this.set('direction', 'forward');
          this.incrementProperty("page");
          this.set('endingBefore', null);
        } else {
          let firstItem = data.firstObject;
          this.set('endingBefore', firstItem.id);
          this.set('direction', 'backward');
          this.decrementProperty("page");
          this.set('startingAfter', null);
        }
      },

      async downloadInvoiceAction(type, companyId, invoice) {
        return await this.productInvoice.downloadInvoice(type, companyId, invoice.id, invoice.number);
      },

      tryCancelInvoice(invoice) {
        this.set('invoice', invoice);
        this.set('tryCancelInvoiceModal', true);
      },

      cancelInvoiceAction(companyId, invoice) {
        this.productInvoice.cancelInvoice(companyId, invoice);
        return this.set('tryCancelInvoiceModal', false);
      },

      openExportModal(type) {
        let issueBegin,
            issueEnd,
            isPeriodInvalid,
            status = 'none';

        if (this.queryDate) {
          let {
            begin,
            end
          } = this.queryDate;
          issueBegin = _moment.default.utc(begin).format("YYYY-MM-DD");
          issueEnd = _moment.default.utc(end).format("YYYY-MM-DD");
        } else {
          let dateBegin = new Date();
          let dateEnd = new Date();
          dateBegin.setDate(dateBegin.getDate() - 31);
          issueBegin = _moment.default.utc(dateBegin).format("YYYY-MM-DD");
          issueEnd = _moment.default.utc(dateEnd).format("YYYY-MM-DD");
        } // let status = this.allSearchProps.filterBy('field', 'status')[0];


        if (type != "analytical-csv") {
          isPeriodInvalid = this.export.isExportNfsePeriodInvalid(issueBegin, issueEnd);
        } // if (type == "pdf" && status != "none") {
        //   status = status == "error" ? [] : [status];
        // }
        // if (type == "xml" && status != "none") {
        //   status = status == "error" ? [] : [status];
        // }


        this.set('exportFilters', {
          type: this.get('exportTypes').find(({
            code
          }) => code == type),
          status: this.get('exportStatusesByType')[type].find(({
            code
          }) => code == status) ?? this.get('exportStatuses').find(({
            code
          }) => code == "none"),
          issueBegin,
          issueEnd,
          isPeriodInvalid: isPeriodInvalid ?? false
        });
        this.set('exportModal', true); // let { status, issueBegin, issueEnd, isPeriodInvalid } = this.allQueryParams;
      }

    },

    elasticQueryBuilder(queryName, value) {
      let newQueryString; // get current elasticSearch filter in URI

      let currQueryString = this.elasticSearch; // set filter variable to the correct format of elasticSearch query string

      let filter = queryName == "createdOn" ? value : `${queryName}:"${value}"`;

      if (currQueryString) {
        // split current queryString to get current filters
        let currFilters = currQueryString.split(" AND "); // verify if new filter is already included in current queryString

        let newFilters = currFilters.filter(query => !query.includes(queryName)); // add new user filter to newFilters array

        newFilters.addObject(filter); // join all filters to make an elasticSearch queryString

        newQueryString = newFilters.join(" AND ");
      } else {
        newQueryString = filter;
      }

      return newQueryString;
    },

    dateQueryBuilder(field, queryName, date) {
      let dateString;
      let dateBegin = (0, _moment.default)(date.begin).format("YYYY-MM-DD");
      let dateEnd = (0, _moment.default)(date.end).format("YYYY-MM-DD");
      if (field == "curWeek" || field == "curMonth") dateString = `${dateBegin} TO *`;else dateString = `${dateBegin} TO ${dateEnd}`;
      return `${queryName}:[${dateString}]`;
    },

    removeElasticQuery(queryName) {
      // get current elasticSearch filter in URI
      let currQueryString = this.elasticSearch; // split current queryString to get current filters

      let currFilters = currQueryString.split(" AND "); // remove wanted filter

      let newFilters = currFilters.filter(query => !query.includes(queryName)); // join all filters to make a queryString if has one or more

      let newQueryString = newFilters.length > 0 ? newFilters.join(" AND ") : null;
      return newQueryString;
    },

    exportNfe: (0, _emberConcurrency.task)(function* (filters) {
      const account = yield this.sessionData.getCurrentAccount();
      let options = {
        companyId: this.model.companyId,
        resource: `company-product-invoice-${filters.type.code}`,
        beginOn: filters.issueBegin,
        endOn: filters.issueEnd,
        apiKey: account.apiKey,
        emails: this.exportEmails
      };

      if (filters.type.code == "analytical-csv") {
        options.status = filters.status.code == "none" ? ["Error", "Cancelled", "Issued"] : [filters.status.code];
      }

      if (filters.type.code == "pdf" && filters.status.code != "none") {
        options.status = filters.status.code == "error" || filters.status.code == "cancelled" ? [] : [filters.status.code];
      }

      if (filters.type.code == "xml" && filters.status.code != "none") {
        options.status = filters.status.code == "error" ? [] : [filters.status.code];
      }

      try {
        yield this.store.adapterFor('export').newExport(account.parentId, account.id, options);
        this.set('exportResponse', {
          success: true,
          message: this.intl.t("account.export.modal.success")
        });
      } catch (error) {
        console.log(error);
        this.set('exportResponse', {
          success: false,
          message: this.intl.t("account.export.modal.error")
        });
      }

      this.set('exportModal', false);
      this.set('exportResponseModal', true);
    })
  });

  _exports.default = _default;
});