define("nfe-customer-console-v2/components/city-not-found-alert/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NdD4HW1p",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"isCityAvailable\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flex-100 flex-gt-sm-100 flex-nogrow bs-callout bs-callout-warning\"],[10,\"id\",\"callout-alerts-no-default\"],[10,\"style\",\"margin-top: 0;\"],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"id\",\"no-default-class\"],[8],[1,[28,\"t\",[\"forms.address.errors.cityNotFound.title\"],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"forms.address.errors.cityNotFound.message\"],null],false],[0,\"\\n      \"],[5,\"paper-button\",[],[[\"@href\",\"@primary\",\"@raised\",\"@target\"],[\"https://nfe.io/docs/prefeituras-integradas/cidades-integradas/#Nao_encontrou_sua_cidade_nessa_lista_Solicite_a_integracao_no_formulario_abaixo\",true,true,\"_blank\"]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"t\",[\"forms.address.errors.cityNotFound.action\"],null],false],[0,\"\\n        \"],[5,\"paper-icon\",[],[[\"@icon\",\"@size\"],[\"arrow_outward\",18]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/city-not-found-alert/template.hbs"
    }
  });

  _exports.default = _default;
});