define("nfe-customer-console-v2/services/batch-access-keys-client", ["exports", "nfe-customer-console-v2/config/environment", "ember-cli-file-saver/mixins/file-saver", "nfe-customer-console-v2/helpers/access-key-validator"], function (_exports, _environment, _fileSaver, _accessKeyValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BatchAccessKeysService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, (_class = (_temp = class BatchAccessKeysService extends Ember.Service.extend(_fileSaver.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "blobHostUrl", _environment.default.APP.AZURE_BLOB_CONTAINER_HOST);

      _initializerDefineProperty(this, "azureBlobStorage", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "sessionData", _descriptor4, this);

      _initializerDefineProperty(this, "user", _descriptor5, this);
    }

    createAndValidateBatch(accessKeys, fileName) {
      let validatedKeys = accessKeys.map(item => {
        let key = typeof item === "string" ? item.trim() : item[0].trim();
        if ((0, _accessKeyValidator.accessKeyValidator)(key)) return key;
      });
      validatedKeys = validatedKeys.uniq().filter(Boolean);
      return Ember.Object.create({
        fileName,
        validKeys: validatedKeys,
        count: {
          totalKeys: accessKeys.length,
          validKeys: validatedKeys.length,
          invalidKeys: accessKeys.length - validatedKeys.length
        }
      });
    }

    async getBatches(params) {
      const account = await this.sessionData.getCurrentAccount();

      if (this.avoidNextQuery) {
        this.set('avoidNextQuery', false);
        return this.store.peekAll('sefaz-nfe-batch');
      }

      const query = {
        accountId: account.id,
        limit: params.limit,
        startingAfter: params.startingAfter,
        endingBefore: params.endingBefore
      };
      return this.store.query('sefaz-nfe-batch', query);
    }

    async sendBatch(batchType, batch) {
      const account = await this.sessionData.getCurrentAccount();
      const user = await this.user.getCurrentUser();
      const accountApikey = await this.store.adapterFor('account').getAllApiKeys(account, x => x.description.includes('Dados (open.nfe.io)'));
      const blobResult = await this.azureBlobStorage.createStorageBlob('exports', `${account.id}/batches/${this._createGuid()}`, batch.validKeys.join("\n"));
      const sefazNfeBatchRecord = this.store.createRecord('sefaz-nfe-batch', {
        accountId: account.id,
        apiKey: accountApikey.firstObject.key,
        name: `${batch.fileName} - ${user.email}`,
        inputBlob: `${this.blobHostUrl}/exports/${blobResult.name}`,
        resource: batchType,
        useCache: true
      });
      const response = await sefazNfeBatchRecord.save();
      this.set('avoidNextQuery', true);
      return response;
    }

    async downloadBatch(fileName, fileType, url, contentType) {
      const response = await this.ajax.request(url, {
        method: 'GET',
        dataType: 'arraybuffer',
        processData: false
      });
      return this.saveFileAs(`${fileName.replace(/(\@|\.)/g, "_")}-${fileType}`, response, contentType);
    }

    _createGuid() {
      const lut = [];

      for (var i = 0; i < 256; i++) {
        lut[i] = (i < 16 ? '0' : '') + i.toString(16);
      }

      var d0 = Math.random() * 0xffffffff | 0;
      return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "azureBlobStorage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BatchAccessKeysService;
  ;
});