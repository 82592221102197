define("nfe-customer-console-v2/models/enums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Enums = Ember.Object.extend();
  Enums.reopenClass({
    apiEnvironments: [{
      text: 'enums.apiEnvironments.development',
      id: 'Development'
    }, {
      text: 'enums.apiEnvironments.production',
      id: 'Production'
    }],
    accountEnvironments: [{
      text: 'enums.apiEnvironments.Test',
      id: 'Test'
    }, {
      text: 'enums.apiEnvironments.Production',
      id: 'Production'
    }],
    invoiceTypes: [{
      text: 'hooks.filters.invoice-type-product',
      id: 'ProductInvoice'
    }, {
      text: 'hooks.filters.invoice-type-consumer',
      id: 'ConsumerInvoice'
    }],
    entityStatuses: [{
      text: 'hooks.new.statusOptions.active',
      id: 'Active'
    } //   {
    //     text: t('hooks.new.statusOptions.inactive'),
    //     id: 'Inactive'
    //   }
    ],
    hookEvents: [{
      text: 'forms.hook.events.eventsOptions.issue',
      id: 'issue'
    }, {
      text: 'forms.hook.events.eventsOptions.cancel',
      id: 'cancel'
    } // {
    //   text: 'forms.hook.events.eventsOptions.pull',
    //   id: 'pull'
    // }
    ],
    hookFilters: [{
      id: "service_invoice-issued_successfully",
      text: "Emitida com sucesso"
    }, {
      id: "service_invoice-issued_failed",
      text: "Falha na emissão"
    }, {
      id: "service_invoice-issued_error",
      text: "Erro na emissão"
    }, {
      id: "service_invoice-cancelled_successfully",
      text: "Cancelada com sucesso"
    }, {
      id: "service_invoice-cancelled_failed",
      text: "Falha no cancelamento"
    }, {
      id: "service_invoice-cancelled_error",
      text: "Erro no cancelamento"
    }, {
      id: "service_invoice-pulled",
      text: "Extraída"
    }, {
      id: "product_invoice-issued_successfully",
      text: "Emitida com sucesso"
    }, {
      id: "product_invoice-issued_failed",
      text: "Falha na emissão"
    }, {
      id: "product_invoice-issued_error",
      text: "Erro na emissão"
    }, {
      id: "product_invoice-cancelled_successfully",
      text: "Cancelada com sucesso"
    }, {
      id: "product_invoice-cancelled_failed",
      text: "Falha no cancelamento"
    }, {
      id: "product_invoice-cancelled_error",
      text: "Erro no cancelamento"
    }, {
      id: "tax_payment_form-created_successfully",
      text: "GNRE emitida com sucesso"
    }, {
      id: "tax_payment_form-creation_failed",
      text: "Falha na emissão da GNRE"
    }, {
      id: "tax_payment_form-creation_not_needed",
      text: "GNRE não necessária"
    }],
    specialTaxRegimes: [{
      code: 'Nenhum',
      name: 'Nenhum'
    }, {
      code: 'Automatico',
      name: 'Automatico'
    }, {
      code: 'MicroempresaMunicipal',
      name: 'Microempresa Municipal'
    }, {
      code: 'Estimativa',
      name: 'Estimativa'
    }, {
      code: 'SociedadeDeProfissionais',
      name: 'Sociedade de Profissionais'
    }, {
      code: 'Cooperativa',
      name: 'Cooperativa'
    }, {
      code: 'MicroempreendedorIndividual',
      name: 'Microempreendedor Individual'
    }, {
      code: 'MicroempresarioEmpresaPequenoPorte',
      name: 'Microempresario e Empresa de Pequeno Porte'
    }],
    taxRegimes: [{
      name: 'Microempreendedor Individual (MEI)',
      code: "MicroempreendedorIndividual"
    }, {
      name: 'Simples Nacional',
      code: 'SimplesNacional'
    }, {
      name: 'Simples Nacional, excesso sublimite da receita bruta',
      code: 'SimplesNacionalExcessoSublimite'
    }, {
      name: 'Lucro Presumido',
      code: "LucroPresumido"
    }, {
      name: 'Lucro Real',
      code: "LucroReal"
    }, {
      name: 'Isento',
      code: "Isento"
    }],
    taxRegimesAccount: [{
      name: 'Microempreendedor Individual (MEI)',
      code: "MEI"
    }, {
      name: 'Simples Nacional',
      code: 'SimplesNacional'
    }, {
      name: 'Lucro Presumido',
      code: "LucroPresumido"
    }, {
      name: 'Lucro Real',
      code: "LucroReal"
    }, {
      name: 'Isento',
      code: "None"
    }],
    legalNatures: [{
      name: "Empresa Pública (201-1)",
      code: "EmpresaPublica",
      id: "2011"
    }, {
      name: "Sociedade de Economia Mista (203-8)",
      code: "SociedadeEconomiaMista",
      id: "2038"
    }, {
      name: "Sociedade Anônima Aberta (204-6)",
      code: "SociedadeAnonimaAberta",
      id: "2046"
    }, {
      name: "Sociedade Anônima Fechada (205-4)",
      code: "SociedadeAnonimaFechada",
      id: "2054"
    }, {
      name: "Sociedade Empresária Limitada (206-2)",
      code: "SociedadeEmpresariaLimitada",
      id: "2062"
    }, {
      name: "Sociedade Empresária em Nome Coletivo (207-0)",
      code: "SociedadeEmpresariaEmNomeColetivo",
      id: "2070"
    }, {
      name: "Sociedade Empresária em Comandita Simples (208-9)",
      code: "SociedadeEmpresariaEmComanditaSimples",
      id: "2089"
    }, {
      name: "Sociedade Empresária em Comandita por Ações (209-7)",
      code: "SociedadeEmpresariaEmComanditaporAcoes",
      id: "2097"
    }, {
      name: "Sociedade em Conta de Participação (212-7)",
      code: "SociedadeemContaParticipacao",
      id: "2127"
    }, {
      name: "Empresário (Individual) (213-5)",
      code: "Empresario",
      id: "2135"
    }, {
      name: "Cooperativa (214-3)",
      code: "Cooperativa",
      id: "2143"
    }, {
      name: "Consórcio de Sociedades (215-1)",
      code: "ConsorcioSociedades",
      id: "2151"
    }, {
      name: "Grupo de Sociedades (216-0)",
      code: "GrupoSociedades",
      id: "2160"
    }, {
      name: "Estabelecimento, no Brasil, de Sociedade Estrangeira (217-8)",
      code: "SociedadeEstrangeiraNoBrasil",
      id: "2178"
    }, {
      name: "Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira (219-4)",
      code: "EmpresaBinacionalArgentinoBrasileira",
      id: "2194"
    }, {
      name: "Empresa Domiciliada no Exterior (221-6)",
      code: "EmpresaDomiciliadaExterior",
      id: "2216"
    }, {
      name: "Clube/Fundo de Investimento (222-4)",
      code: "ClubeFundoInvestimento",
      id: "2224"
    }, {
      name: "Sociedade Simples Pura (223-2)",
      code: "SociedadeSimplesPura",
      id: "2232"
    }, {
      name: "Sociedade Simples Limitada (224-0)",
      code: "SociedadeSimplesLimitada",
      id: "2240"
    }, {
      name: "Sociedade Simples em Nome Coletivo (225-9)",
      code: "SociedadeSimplesEmNomeColetivo",
      id: "2259"
    }, {
      name: "Sociedade Simples em Comandita Simples (226-7)",
      code: "SociedadeSimplesEmComanditaSimples",
      id: "2267"
    }, {
      name: "Empresa Binacional (227-5)",
      code: "EmpresaBinacional",
      id: "2275"
    }, {
      name: "Consórcio de Empregadores (228-3)",
      code: "ConsorcioEmpregadores",
      id: "2283"
    }, {
      name: "Consórcio Simples (229-1)",
      code: "ConsorcioSimples",
      id: "2291"
    }, {
      name: "Empresa Individual de Responsabilidade Limitada (de Natureza Empresária) (230-5)",
      code: "EireliNaturezaEmpresaria",
      id: "2305"
    }, {
      name: "Empresa Individual de Responsabilidade Limitada (de Natureza Simples) (231-3)",
      code: "EireliNaturezaSimples",
      id: "2313"
    }, {
      name: "Sociedade Unipessoal de Advogados (232-1)",
      code: "SociedadeUnipessoaldeAdvogados",
      id: "2321"
    }, {
      name: "Cooperativas de Consumo (233-0)",
      code: "CooperativaDeConsumo",
      id: "2330"
    }, {
      name: "Empresa Simples de Inovação - Inova Simples (234-8)",
      code: "EmpresaSimplesDeInovacao",
      id: "2348"
    }, {
      name: "Investidor Não Residente (235-6)",
      code: "InvestidorNaoResidente",
      id: "2356"
    }, {
      name: "Serviço Notarial e Registral (Cartório) (303-4)",
      code: "ServicoNotarial",
      id: "3034"
    }, {
      name: "Fundação Privada (306-9)",
      code: "FundacaoPrivada",
      id: "3069"
    }, {
      name: "Serviço Social Autônomo (307-7)",
      code: "ServicoSocialAutonomo",
      id: "3077"
    }, {
      name: "Condomínio Edilício (308-5)",
      code: "CondominioEdilicio",
      id: "3085"
    }, {
      name: "Comissão de Conciliação Prévia (310-7)",
      code: "ComissaoConciliacaoPrevia",
      id: "3107"
    }, {
      name: "Entidade de Mediação e Arbitragem (311-5)",
      code: "EntidadeMediacaoArbitragem",
      id: "3115"
    }, {
      name: "Partido Político (312-3)",
      code: "PartidoPolitico",
      id: "3123"
    }, {
      name: "Entidade Sindical (313-1)",
      code: "EntidadeSindical",
      id: "3131"
    }, {
      name: "Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras (320-4)",
      code: "EstabelecimentoBrasilFundacaoAssociacaoEstrangeiras",
      id: "3204"
    }, {
      name: "Fundação ou Associação Domiciliada no Exterior (321-2)",
      code: "FundacaoAssociacaoDomiciliadaExterior",
      id: "3212"
    }, {
      name: "Organização Religiosa (322-0)",
      code: "OrganizacaoReligiosa",
      id: "3220"
    }, {
      name: "Comunidade Indígena (323-9)",
      code: "ComunidadeIndigena",
      id: "3239"
    }, {
      name: "Fundo Privado (324-7)",
      code: "FundoPrivado",
      id: "3247"
    }, {
      name: "Órgão de Direção Nacional de Partido Político (325-5)",
      code: "OrgaoDirecaoNacionalPartidoPolitico",
      id: "3255"
    }, {
      name: "Órgão de Direção Regional de Partido Político (326-3)",
      code: "OrgaoDirecaoRegionalPartidoPolitico",
      id: "3263"
    }, {
      name: "Órgão de Direção Local de Partido Político (327-1)",
      code: "OrgaoDirecaoLocalPartidoPolitico",
      id: "3271"
    }, {
      name: "Comitê Financeiro de Partido Político (328-0)",
      code: "ComiteFinanceiroDePartidoPolitico",
      id: "3280"
    }, {
      name: "Frente Plebiscitária ou Referendária (329-8)",
      code: "FrentePlebiscitariaOuReferendaria",
      id: "3298"
    }, {
      name: "Organização Social (OS) (330-1)",
      code: "OrganizacaoSocial",
      id: "3301"
    }, {
      name: "Demais Condomínios (331-0)",
      code: "DemaisCondominios",
      id: "3310"
    }, {
      name: "Plano de Benefícios de Previdência Complementar Fechada (332-8)",
      code: "PlanoBeneficiosPrevidenciaComplementarFechada",
      id: "3328"
    }, {
      name: "Associação Privada (399-9)",
      code: "AssociacaoPrivada",
      id: "3999"
    }, {
      name: "Empresa Individual Imobiliária (401-4)",
      code: "EmpresaIndividualImobiliaria",
      id: "4014"
    }, {
      name: "Segurado Especial (402-2)",
      code: "SeguradoEspecial",
      id: "4022"
    }, {
      name: "Contribuinte individual (408-1)",
      code: "ContribuinteIndividual",
      id: "4081"
    }, {
      name: "Candidato a Cargo Político Eletivo (409-0)",
      code: "CandidatoCargoPoliticoEletivo",
      id: "4090"
    }, {
      name: "Leiloeiro (411-1)",
      code: "Leiloeiro",
      id: "4111"
    }, {
      name: "Produtor Rural (Pessoa Física) (412-0)",
      code: "ProdutorRural",
      id: "4120"
    }, {
      name: "Organização Internacional (501-0)",
      code: "OrganizacaoInternacional",
      id: "5010"
    }, {
      name: "Representação Diplomática Estrangeira (502-9)",
      code: "RepresentacaoDiplomaticaEstrangeira",
      id: "5029"
    }, {
      name: "Outras Instituições Extraterritoriais (503-7)",
      code: "OutrasInstituicoesExtraterritoriais",
      id: "503"
    }],
    exportNfseTypes: [{
      name: "companies.serviceInvoices.types.pdf",
      code: "pdf"
    }, {
      name: "companies.serviceInvoices.types.xml",
      code: "xml"
    }, {
      name: "companies.serviceInvoices.types.analytical-csv",
      code: "analytical-csv"
    }],
    exportNfseStatuses: [{
      name: "companies.serviceInvoices.status.None",
      code: "none"
    }, {
      name: "companies.serviceInvoices.status.Issued",
      code: "issued"
    }, {
      name: "companies.serviceInvoices.status.Cancelled",
      code: "cancelled"
    }, {
      name: "companies.serviceInvoices.status.Error",
      code: "error"
    }],
    exportNfeTypes: [{
      name: "companies.serviceInvoices.types.pdf",
      code: "pdf"
    }, {
      name: "companies.serviceInvoices.types.xml",
      code: "xml"
    }, {
      name: "companies.serviceInvoices.types.analytical-csv",
      code: "analytical-csv"
    }],
    exportNfeStatuses: [{
      name: "companies.serviceInvoices.status.None",
      code: "none"
    }, {
      name: "companies.serviceInvoices.status.Issued",
      code: "issued"
    }, {
      name: "companies.serviceInvoices.status.Cancelled",
      code: "cancelled"
    }, {
      name: "companies.serviceInvoices.status.Error",
      code: "error"
    }],
    nfseStatuses: [{
      text: "companies.serviceInvoices.status.None",
      id: 'none'
    }, {
      text: "companies.serviceInvoices.status.Issued",
      id: 'issued'
    }, {
      text: "companies.serviceInvoices.status.Cancelled",
      id: 'cancelled'
    }, {
      text: "companies.serviceInvoices.status.Error",
      id: 'error'
    }, {
      text: "companies.serviceInvoices.status.Created",
      id: 'created'
    }],
    nfeStatuses: [{
      text: "companies.productInvoices.status.none",
      id: 'none'
    }, {
      text: "companies.productInvoices.status.issued",
      id: 'issued'
    }, {
      text: "companies.productInvoices.status.cancelled",
      id: 'cancelled'
    }, {
      text: "companies.productInvoices.status.error",
      id: 'error'
    }, {
      text: "companies.productInvoices.status.created",
      id: 'created'
    }, {
      text: "companies.productInvoices.status.processing",
      id: 'processing'
    }, {
      text: "companies.productInvoices.status.issueDenied",
      id: 'issueDenied'
    }, {
      text: "companies.productInvoices.status.disabled",
      id: "disabled"
    }],
    flowStatus: [{
      text: 'Falha ao cancelar',
      id: 'CancelFailed'
    }, {
      text: 'Falha ao emitir',
      id: 'IssueFailed'
    }, {
      text: 'Emitida',
      id: 'Issued'
    }, {
      text: 'Cancelada',
      id: 'Cancelled'
    }, {
      text: 'Calculando impostos',
      id: 'WaitingCalculateTaxes'
    }, {
      text: 'Aguardando',
      id: 'WaitingDefineRpsNumber'
    }, {
      text: 'Aguardando',
      id: 'WaitingSend'
    }, {
      text: 'Aguardando',
      id: 'WaitingSendCancel'
    }, {
      text: 'Aguardando',
      id: 'WaitingReturn'
    }, {
      text: 'Aguardando',
      id: 'WaitingDownload'
    }],
    accessKeysInfo: [{
      text: "account.accessKeys.types.data",
      id: "Dados (open.nfe.io)"
    }, {
      text: "account.accessKeys.types.issueInvoices",
      id: "Nota Fiscal (api.nfe.io)"
    }],
    nfeDistWaitingTimes: [{
      name: '5 minutos',
      code: 5
    }, {
      name: '10 minutos',
      code: 10
    }, {
      name: '30 minutos',
      code: 30
    }, {
      name: '1 hora',
      code: 60
    }, {
      name: '2 horas',
      code: 120
    }, {
      name: '4 horas',
      code: 240
    }, {
      name: '8 horas',
      code: 480
    }, {
      name: '16 horas',
      code: 960
    }, {
      name: '24 horas',
      code: 1440
    }, {
      name: '48 horas',
      code: 2880
    }, {
      name: '72 horas',
      code: 4320
    }],
    nfeTypes: [{
      name: 'NFe',
      code: 'NFe'
    }, {
      name: 'NFCe',
      code: 'NFCe'
    }],
    taxDeterminations: [{
      code: 'NotInformed',
      name: 'Não informado'
    }, {
      code: 'Default',
      name: 'Padrão'
    }, {
      code: 'SimplesNacional',
      name: 'Definido pelo Simples Nacional'
    }]
  });
  var _default = Enums;
  _exports.default = _default;
});