define("nfe-customer-console-v2/services/mount-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    features: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    firstCompanyId: null,
    emptyCompany: null,
    enabledCompaniesMenuItems: null,
    enabledConsultsMenuItems: null,
    isRunning: false,

    async mountHeader(companies) {
      this.set("isRunning", true); // We call the getCurrentAccount() from sessionData only
      // to active all the providers for the account

      const account = await this.sessionData.getCurrentAccount();
      this.enableMenuItems(this.companiesMenuItems, "enabledCompaniesMenuItems");
      this.enableMenuItems(this.consultsMenuItems, "enabledConsultsMenuItems");
      await this.checkCompanies(account, companies);
      this.set("isRunning", false);
    },

    enableMenuItems(menuItems, property) {
      menuItems.forEach(item => {
        if (item.flags) {
          item.isEnabled = item.flags.some(flag => this.features.isEnabled(flag));
        } else {
          item.isEnabled = this.features.isEnabled(item.flag);
        }
      });
      let enabledItems = menuItems.filterBy('isEnabled', true);
      if (enabledItems.length === 1) return this.set(property, enabledItems[0]);
      return this.set(property, enabledItems);
    },

    async checkCompanies(account, companies) {
      if (!companies && account && account.id) {
        if (this.features.get('dFeTechServiceInvoice')) {
          try {
            companies = await this.store.query('company', {
              accountId: account.id
            });
          } catch (e) {
            if (e.code === 404 || e.errors[0].status === '404') {
              companies = [];
              this.set('emptyCompany', true);
            }

            console.error(e);
          }
        } else if (this.features.get('dFeTechProductInvoice')) {
          companies = await this.store.query('company-v2', {
            limit: 3
          });
        }
      }

      if (companies && companies.length == 1) {
        this.set('firstCompanyId', companies.firstObject.id);
      }
    },

    companiesMenuItems: Ember.A([{
      flag: 'dFeTechServiceInvoice',
      name: 'NFS-e',
      route: 'companies',
      editRoute: 'companies.edit',
      createRoute: 'companies.new',
      invoicesRoute: 'companies.service-invoices',
      isEnabled: false
    }, {
      flag: 'dFeTechProductInvoice',
      name: 'NF-e',
      route: 'companies-v2',
      editRoute: 'companies-v2.edit',
      createRoute: 'companies-v2.new',
      invoicesRoute: 'companies-v2.product-invoices',
      isEnabled: false
    } // {
    //     flag: 'dFeTechConsumerInvoice',
    //     name: 'NFCe',
    //     route: '',
    //     isEnabled: this.features.isEnabled('dFeTechConsumerInvoice')
    // }
    ]),
    consultsMenuItems: Ember.A([{
      flags: ['dataTechBatchProductInvoicePdf', 'dataTechBatchProductInvoiceXml', 'dataTechBatchProductInvoiceSerproPdf', 'dataTechBatchProductInvoiceSerproXml'],
      name: 'NF-e',
      route: 'nfe',
      isEnabled: false
    }, {
      flag: 'dataTechLegalEntity',
      name: 'CNPJ',
      route: 'consults.legal-entity',
      isEnabled: false
    }, {
      flag: 'dataTechNaturalPerson',
      name: 'CPF',
      route: 'consults.natural-person',
      isEnabled: false
    }, {
      flag: 'dFeTechProductInvoiceInbound',
      name: 'NF-e distribuição',
      route: 'consults.dfe-dist',
      isEnabled: false
    }, {
      flag: 'dFeTechTransportationInvoiceInbound',
      name: 'CT-e distribuição',
      route: 'consults.dfe-dist',
      isEnabled: false
    }])
  });

  _exports.default = _default;
});