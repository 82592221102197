define("nfe-customer-console-v2/models/apikey", ["exports", "nfe-customer-console-v2/models/enums", "ember-data-model-fragments/fragment", "@ember-data/model"], function (_exports, _enums, _fragment, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fragment.default.extend({
    description: (0, _model.attr)('string'),
    parentId: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    createdOn: (0, _model.attr)('string'),
    modifiedOn: (0, _model.attr)('string'),
    isActive: Ember.computed('data,status', function () {
      return this.status?.toLowerCase() === 'active';
    }),
    getAccessKeyInfo: Ember.computed('description', function () {
      return _enums.default.accessKesInfo.filter(item => {
        let value = this.description;
        return value === item.id;
      })[0];
    })
  });

  _exports.default = _default;
});