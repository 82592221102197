define("nfe-customer-console-v2/routes/companies/new", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NewCompanyRoute extends _authenticated.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "titleToken", "Cadastrar empresa NFS-e");
    }

    model() {
      return this.store.createRecord('company', {
        address: this.store.createRecord('address', {
          city: this.store.createRecord('address-city')
        })
      });
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.model.rollbackAttributes();
        controller.setProperties({
          activateCompanyValidations: false,
          activateCertificateValidations: false,
          activatePasswordValidations: false,
          certificatePassword: null,
          certificate: null,
          file: null,
          currentStep: 0,
          companyId: null,
          isCompanyCreated: false,
          isCertificateSent: false,
          isCertificateSkiped: false,
          onboarding: false
        });
      }
    }

  }

  _exports.default = NewCompanyRoute;
});