define("nfe-customer-console-v2/adapters/product-invoice", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _baseRest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ProductInvoiceAdapter = (_dec = Ember.inject.service, (_class = (_temp = class ProductInvoiceAdapter extends _baseRest.default.extend(_emberDataUrlTemplates.default) {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _defineProperty(this, "host", _environment.default.APP.NFSE_V2_API_URL);

      _defineProperty(this, "namespace", 'v2');

      _defineProperty(this, "apikeyType", 'Nota Fiscal (api.nfe.io)');

      _defineProperty(this, "queryUrlTemplate", '{+host}/{+namespace}/companies/{companyId}/productinvoices{?environment,limit,startingAfter,endingBefore,q}');

      _defineProperty(this, "queryRecordUrlTemplate", '{+host}/{+namespace}/companies/{companyId}/productinvoices/{invoiceId}');

      _defineProperty(this, "deleteRecordUrlTemplate", '{+host}/{+namespace}/companies/{companyId}/productinvoices/{invoiceId}');

      _defineProperty(this, "urlSegments", {
        host() {
          return this.get('host');
        },

        namespace() {
          return this.get('namespace');
        },

        companyId(type, id, snapshot, query) {
          let companyId;

          if (query) {
            companyId = query.companyId;
          } else if (snapshot && snapshot.adapterOptions) {
            companyId = snapshot.adapterOptions.companyId;
          } else {
            companyId = snapshot.companyId;
          }

          return companyId;
        },

        invoiceId(type, id, snapshot, query) {
          let invoiceId = id;

          if (query) {
            invoiceId = query.invoiceId;
          } else if (snapshot.invoiceId) {
            invoiceId = snapshot.invoiceId;
          }

          return invoiceId;
        },

        environment(type, id, snapshot, query) {
          return query.environment;
        },

        invoiceType(type, id, snapshot, query) {
          return query.invoiceType;
        },

        limit(type, id, snapshot, query) {
          return query.limit;
        },

        q(type, id, snapshot, query) {
          return query.q;
        },

        startingAfter(type, id, snapshot, query) {
          return query.startingAfter;
        },

        endingBefore(type, id, snapshot, query) {
          return query.endingBefore;
        }

      });
    }

    get headers() {
      const {
        apiKeys,
        id,
        parentId
      } = this.sessionData.account;
      const {
        key,
        data
      } = apiKeys?.find(({
        description
      }) => description === this.apikeyType) || {};

      if (!apiKeys?.length || !key || data != 'Active') {
        console.error(`No Apikey (${this.apikeyType}) found for account (id/parentId) ${parentId || id}`);
        return {};
      }

      return {
        Authorization: apiKeys.find(({
          description
        }) => description === this.apikeyType)?.key || null
      };
    }

    getEvents(companyId, invoiceId, query) {
      const url = this.buildURL('product-invoice', null, {
        companyId,
        invoiceId
      }, 'queryRecord') + '/events';
      const {
        limit,
        startingAfter
      } = query;
      return this.ajax(url, 'GET', {
        data: {
          limit,
          startingAfter
        }
      });
    }

    downloadInvoice(type, companyId, invoiceId) {
      const url = this.buildURL('product-invoice', null, {
        companyId,
        invoiceId
      }, 'queryRecord') + '/' + type;
      return this.ajax(url);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ProductInvoiceAdapter;
  ;
});