define("nfe-customer-console-v2/adapters/account", ["exports", "nfe-customer-console-v2/adapters/base-rest", "nfe-customer-console-v2/config/environment", "ember-data-url-templates"], function (_exports, _baseRest, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRest.default.extend(_emberDataUrlTemplates.default, {
    queryUrlTemplate: '{+host}{/+namespace}/accounts{?pageIndex,pageCount,email,search}',
    queryRecordUrlTemplate: '{+host}{/+namespace}/accounts/{account_id}',
    createRecordUrlTemplate: '{+host}{/+namespace}/accounts/',
    updateRecordUrlTemplate: '{+host}{/+namespace}/accounts/{id}',

    async query() {
      return await this.ajax(`${this.host}/accounts`, 'GET', {
        headers: this.headersToken
      });
    },

    host: _environment.default.APP.API_URL,

    async queryRecord(adapter, model, params) {
      return await this.ajax(`${this.host}/accounts/${params.account_id}`, 'GET', {
        headers: this.headersToken
      });
    },

    async findRecord(adapter, model, account_id) {
      return await this.ajax(`${this.host}/accounts/${account_id}`, 'GET', {
        headers: this.headersToken
      });
    },

    create(model) {
      return this.ajax(`${this.host}/accounts`, 'POST', {
        data: model,
        headers: this.headersToken
      });
    },

    update(model) {
      return this.ajax(`${this.host}/accounts/${model.id}`, 'PUT', {
        data: model.serialize(),
        headers: this.headersToken
      });
    },

    setEnvironment(model, environment) {
      const url = this.buildURL('account', model.get('id')) + `/set-environment/${environment}`;
      return this.ajax(url, 'PUT', {
        headers: this.headersToken
      });
    },

    activate(model) {
      const url = this.buildURL('account', model.get('id')) + "/activate";
      return this.ajax(url, 'PUT', {
        headers: this.headersToken
      });
    },

    deactivate(model) {
      const url = this.buildURL('account', model.get('id')) + "/deactivate";
      return this.ajax(url, 'PUT', {
        headers: this.headersToken
      });
    },

    updateUsage(model, beginDate, endDate) {
      return this.ajax(`${_environment.default.APP.USAGES_API_URL}/api/orchestrators/accounts/${model.get('id')}/usages/${beginDate}/${endDate}/sum`, 'PUT', {
        headers: this.headersToken
      });
    },

    updateUsageAll(beginDate, endDate) {
      return this.ajax(`${_environment.default.APP.USAGES_API_URL}/api/orchestrators/accounts/*/usages/${beginDate}/${endDate}/sum`, 'PUT', {
        headers: this.headersToken
      });
    },

    async getAllApiKeys(model, filter = null) {
      const result = await this.ajax(`${_environment.default.APP.IDENTITY_API_URL}/subjects/${model.id}/grants`);
      if (!filter) filter = () => true;
      return result.filter(filter);
    }

  });

  _exports.default = _default;
});