define("nfe-customer-console-v2/components/issue-batch-stepper/component", ["exports", "ember-cp-validations", "ember-concurrency"], function (_exports, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = (0, _emberCpValidations.buildValidations)({
    spreadsheet: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'Selecione sua planilha.'
    }), (0, _emberCpValidations.validator)('format', {
      regex: /.*\.(xlsx)$/gi,
      message: 'Selecione uma planilha de excel válida.'
    })]
  });

  var _default = Ember.Component.extend(validations, {
    tagName: '',
    batchLimit: 50,
    features: Ember.inject.service(),
    modalData: Ember.computed('selectedSheet', function () {
      const data = {
        videoUrl: "https://www.youtube.com/embed/Jfyz3S8wd_c",
        docLink: "https://nfe.io/docs/nossa-plataforma/nota-fiscal-servico/emitir-nota-servico/"
      };

      if (this.selectedSheet === 'advanced') {
        data.videoUrl = "https://www.youtube.com/embed/OBDz_qgOlcM";
      }

      return data;
    }),
    statusReadFile: {
      status: "success",
      message: "Planilha importada com sucesso!",
      icon: "check"
    },
    readFile: (0, _emberConcurrency.task)(function* (nextStep) {
      nextStep();
      yield (0, _emberConcurrency.timeout)(3000);
      const reader = new FileReader();

      reader.onload = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: 'array'
        });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];

        if (this.get("features.dFeTechServiceInvoiceBatch")) {
          this.set('batchLimit', 500);
        }

        const excelRows = this.parseSheet(XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false
        }));

        if (excelRows.length > this.batchLimit) {
          this.set('statusReadFile', {
            status: "error",
            message: `Insira um planilha com no máximo ${this.batchLimit} notas fiscais`,
            icon: "error-outline"
          });
        } else {
          excelRows.forEach(item => this.validateFields(item));
          this.set('results', excelRows.map(item => Ember.Object.create(item)));
        }
      };

      reader.readAsArrayBuffer(this.get("file"));
    }),

    parseSheet(rows) {
      rows[0] = rows[0].map(field => field.toLowerCase());
      const requiredFields = [{
        field: 'nome',
        displayName: 'Nome'
      }, {
        field: 'valor',
        displayName: 'Valor'
      }, {
        field: 'codigo_servico',
        displayName: 'Codigo_Servico'
      }, {
        field: 'endereco_pais',
        displayName: 'Endereco_Pais'
      }, {
        field: 'descricao',
        displayName: 'Descricao'
      }];
      requiredFields.forEach(({
        field,
        displayName
      }) => {
        if (!rows[0].includes(field)) {
          this.set('statusReadFile', {
            status: "error",
            message: `Campo ${displayName} não encontrado na planilha. Verifique se o nome da coluna está correto, conforme as planilhas de exemplo.`,
            icon: "error-outline"
          });
        }
      });
      const fieldIndexes = this.getFieldIndexes(rows[0]);
      return rows.slice(1).map(row => this.formatRow(row, fieldIndexes));
    },

    getFieldIndexes(headers) {
      const fields = ['cpf_cnpj', 'nome', 'valor', 'codigo_servico', 'endereco_pais', 'endereco_cep', 'endereco_logradouro', 'endereco_numero', 'endereco_complemento', 'endereco_bairro', 'endereco_cidade_codigo', 'endereco_cidade_nome', 'endereco_estado', 'descricao', 'estado_prestacao_servico', 'cidade_prestacao_servico', 'codigo_cidade_prestacao_servico', 'retencao_ir', 'retencao_pis', 'retencao_cofins', 'retencao_csll', 'retencao_inss', 'retencao_iss', 'retencao_outros', 'email', 'data_competencia', 'informacoes_adicionais', 'tipo_tributacao', 'aliquota_iss', 'valor_iss', 'cnae', 'inscricao_municipal', 'tipo_tomador', 'id_externo', 'valor_deducoes', 'valor_desconto_incondicionado', 'valor_desconto_condicionado', 'valor_recebido'];
      return fields.reduce((acc, field) => {
        acc[field] = headers.indexOf(field);
        return acc;
      }, {});
    },

    formatRow(row, fieldIndexes) {
      const formatAmount = (value, hasDecimals = true) => {
        let result = 0;
        const dotIndex = value.indexOf(".");
        const commaIndex = value.indexOf(",");

        if (dotIndex >= 0 && commaIndex === -1 && value.lastIndexOf(".") === dotIndex) {
          result = Number(value);
        } else if (commaIndex >= 0 && dotIndex === -1 && value.lastIndexOf(",") === commaIndex) {
          result = Number(value.replace(",", "."));
        } else {
          if (commaIndex === -1) {
            result = Number(value.replace(/[.]/g, ""));
          } else if (dotIndex === -1) {
            result = Number(value.replace(/[,]/g, ""));
          } else if (dotIndex < commaIndex) {
            result = Number(value.replace(/[.]/g, "").replace(",", "."));
          } else {
            result = Number(value.replace(/[,]/g, ""));
          }
        }

        if (!hasDecimals) result = result | 0;
        return result;
      };

      if (row[0]) row[0] = row[0].toString().replace(/\D/g, '');
      if (row[fieldIndexes.aliquota_iss]) row[fieldIndexes.aliquota_iss] = row[fieldIndexes.aliquota_iss] / 100;
      const amountFields = ['valor', 'valor_iss', 'retencao_ir', 'retencao_pis', 'retencao_cofins', 'retencao_csll', 'retencao_inss', 'retencao_iss', 'retencao_outros', 'valor_deducoes', 'valor_desconto_incondicionado', 'valor_desconto_condicionado', 'valor_recebido'];
      amountFields.forEach(field => {
        if (row[fieldIndexes[field]] && typeof row[fieldIndexes[field]] !== 'number') {
          row[fieldIndexes[field]] = formatAmount(row[fieldIndexes[field]]);
        }
      });
      return {
        CPF_CNPJ: row[fieldIndexes.cpf_cnpj],
        Nome: row[fieldIndexes.nome],
        Email: row[fieldIndexes.email],
        Valor: row[fieldIndexes.valor],
        Codigo_Servico: row[fieldIndexes.codigo_servico],
        Endereco_Pais: row[fieldIndexes.endereco_pais],
        Endereco_Cep: row[fieldIndexes.endereco_cep],
        Endereco_Logradouro: row[fieldIndexes.endereco_logradouro],
        Endereco_Numero: row[fieldIndexes.endereco_numero],
        Endereco_Complemento: row[fieldIndexes.endereco_complemento],
        Endereco_Bairro: row[fieldIndexes.endereco_bairro],
        Endereco_Cidade_Codigo: row[fieldIndexes.endereco_cidade_codigo],
        Endereco_Cidade_Nome: row[fieldIndexes.endereco_cidade_nome],
        Endereco_Estado: row[fieldIndexes.endereco_estado],
        Descricao: row[fieldIndexes.descricao],
        Data_Competencia: row[fieldIndexes.data_competencia],
        CNAE: row[fieldIndexes.cnae],
        Inscricao_Municipal: row[fieldIndexes.inscricao_municipal],
        Informacoes_Adicionais: row[fieldIndexes.informacoes_adicionais],
        Tipo_Tributacao: row[fieldIndexes.tipo_tributacao],
        Aliquota_ISS: row[fieldIndexes.aliquota_iss],
        Valor_ISS: row[fieldIndexes.valor_iss],
        Retencao_IR: row[fieldIndexes.retencao_ir],
        Retencao_PIS: row[fieldIndexes.retencao_pis],
        Retencao_COFINS: row[fieldIndexes.retencao_cofins],
        Retencao_CSLL: row[fieldIndexes.retencao_csll],
        Retencao_INSS: row[fieldIndexes.retencao_inss],
        Retencao_ISS: row[fieldIndexes.retencao_iss],
        Retencao_OUTROS: row[fieldIndexes.retencao_outros],
        Valor_Deducoes: row[fieldIndexes.valor_deducoes],
        Valor_Desconto_Incondicionado: row[fieldIndexes.valor_desconto_incondicionado],
        Valor_Desconto_Condicionado: row[fieldIndexes.valor_desconto_condicionado],
        Tipo_Tomador: row[fieldIndexes.tipo_tomador],
        Estado_Prestacao_Servico: row[fieldIndexes.estado_prestacao_servico],
        Cidade_Prestacao_Servico: row[fieldIndexes.cidade_prestacao_servico],
        Codigo_Cidade_Prestacao_Servico: row[fieldIndexes.codigo_cidade_prestacao_servico],
        ID_Externo: row[fieldIndexes.id_externo],
        Valor_Recebido: row[fieldIndexes.valor_recebido]
      };
    },

    validateFields(item) {
      const requiredFields = ['Nome', 'Valor', 'Codigo_Servico', 'Endereco_Pais', 'Descricao'];
      const invalidFields = [];

      if (item.Data_Competencia && !item.Data_Competencia.toString().includes('-')) {
        invalidFields.push('Data_Competencia');
      }

      if (isNaN(item.Valor)) {
        invalidFields.push('Valor');
      }

      if (item.Tipo_Tributacao) {
        const taxationTypeList = Ember.A(["None", "WithinCity", "OutsideCity", "Export", "Free", "Immune", "SuspendedCourtDecision", "SuspendedAdministrativeProcedure", "OutsideCityFree", "OutsideCityImmune", "OutsideCitySuspended", "OutsideCitySuspendedAdministrativeProcedure", "ObjectiveImune", "ObjectiveOutsideCityImune"]);

        if (!taxationTypeList.includes(item.Tipo_Tributacao)) {
          invalidFields.push('Tipo_Tributacao');
        }
      }

      requiredFields.forEach(field => {
        if (!item[field]) {
          invalidFields.push(field);
        }
      });

      if (invalidFields.length > 0) {
        item.hasInvalidFields = true;
        item.invalidFields = invalidFields;
      }

      return item;
    },

    actions: {
      uploadSheet({
        target: {
          files: [file]
        }
      }) {
        this.setProperties({
          spreadsheet: file.name,
          file,
          activateFileValidations: true
        });
      },

      cleanInput({
        target
      }) {
        document.querySelector(`#${target.id}`).value = null;
        this.setProperties({
          statusReadFile: {
            status: "success",
            message: "Planilha importada com sucesso!",
            icon: "check"
          },
          spreadsheet: null,
          file: null,
          activateFileValidations: false
        });
      },

      selectSheet(selected) {
        this.setProperties({
          selectedBasicSheet: false,
          selectedAdvancedSheet: false
        });
        this.set(selected, true);
      }

    }
  });

  _exports.default = _default;
});