define("nfe-customer-console-v2/components/service-invoice-actions-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serviceInvoice: Ember.inject.service(),
    router: Ember.inject.service(),
    ticket: Ember.inject.service(),
    tagName: "",
    createdInvoice: Ember.computed.equal("invoice.status", "Created"),
    issuedInvoice: Ember.computed.equal("invoice.status", "Issued"),
    cancelledInvoice: Ember.computed.equal("invoice.status", "Cancelled"),
    isEditRoute: Ember.computed('router.currentRouteName', function () {
      return this.router.currentRouteName.includes('companies.edit');
    }),
    isInvoicesRoute: Ember.computed.equal('router.currentRouteName', 'companies.service-invoices.index'),
    isIssueRoute: Ember.computed.equal('router.currentRouteName', 'companies.service-invoices.batch-issue'),
    actions: {
      downloadInvoice(type, companyId, invoice) {
        return this.serviceInvoice.download(type, invoice, companyId);
      },

      reprocessPdf(companyId, invoice) {
        return this.serviceInvoice.download("pdf", invoice, companyId, true);
      },

      sendEmail(companyId, invoice) {
        return this.serviceInvoice.sendEmail(companyId, invoice);
      },

      cancelInvoice(companyId, invoice) {
        this.set('tryCancelInvoiceModal', false);
        return this.serviceInvoice.cancel(companyId, invoice);
      },

      duplicateInvoice(companyId, invoiceId) {
        this.set('tryDuplicateInvoiceModal', false);
        return this.serviceInvoice.duplicate(companyId, invoiceId);
      }

    },
    defaultItems: Ember.A([{
      name: "editCompany",
      route: "companies.edit",
      icon: "edit",
      label: "companies.actions.editLong"
    }, {
      name: "listInvoices",
      route: "companies.service-invoices",
      icon: "view_list",
      label: "companies.actions.listServiceInvoicesLong"
    }, {
      name: "issueBatchInvoices",
      route: "companies.service-invoices.batch-issue",
      icon: "receipt",
      label: "companies.actions.issueBatchInvoicesLong"
    }, {
      name: "testIssue",
      route: "onboard.service-invoice.issue-step",
      icon: "task",
      label: "companies.actions.testIssue"
    }]),
    filteredDefaultItems: Ember.computed('defaultItems', function () {
      const {
        isEditRoute,
        isInvoicesRoute,
        isIssueRoute,
        defaultItems
      } = this;
      let filtered;

      if (isInvoicesRoute) {
        filtered = defaultItems.filter(item => item.name == "editCompany" || item.name == "issueBatchInvoices");
      } else if (isIssueRoute) {
        filtered = defaultItems.filter(item => item.name == "editCompany" || item.name == "listInvoices");
      } else if (isEditRoute) {
        filtered = defaultItems.filter(item => item.name == "issueBatchInvoices" || item.name == "listInvoices" || item.name == "testIssue");
      }

      return filtered;
    })
  });

  _exports.default = _default;
});