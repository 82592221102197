define("nfe-customer-console-v2/routes/companies/edit/index", ["exports", "nfe-customer-console-v2/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompanyEditRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = (_temp = class CompanyEditRoute extends _authenticated.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "sessionData", _descriptor2, this);

      _defineProperty(this, "titleToken", "Gerenciamento da empresa");
    }

    async model({
      companyId
    }) {
      const account = await this.sessionData.getCurrentAccount();
      const company = await this.store.queryRecord('company', {
        accountId: account.id,
        companyId
      });
      const apiKey = await this.sessionData.getApiKeysByFilter(key => key.description.includes("Nota Fiscal (api.nfe.io)"));

      if (!apiKey.length) {
        throw new Error("Chave de API para emissão de notas fiscais não encontrada");
      }

      const invoices = await this.store.query('service-invoice-query', {
        accountId: account.id,
        companyId: company.id,
        page: 1,
        perPage: 1
      });
      company.set("apikey", apiKey);
      company.set("hasServiceInvoicesIssued", invoices.length > 0);
      return company;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CompanyEditRoute;
  ;
});