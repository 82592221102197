define("nfe-customer-console-v2/components/file-upload-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+mPknrFJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[10,\"id\",\"file\"],[10,\"name\",\"file\"],[11,\"onchange\",[22,\"onChange\"]],[11,\"onclick\",[22,\"onClick\"]],[10,\"hidden\",\"\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"],[7,\"label\",true],[10,\"for\",\"file\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"for\",\"file\"],[10,\"class\",\"md-default-theme md-button md-raised\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"forms.certificate.selectFileBtn\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/file-upload-button/template.hbs"
    }
  });

  _exports.default = _default;
});