define("nfe-customer-console-v2/router", ["exports", "nfe-customer-console-v2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    environment: _environment.default.environment,
    metrics: Ember.inject.service(),
    headData: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.on('routeDidChange', transition => {
        window.scrollTo(0, 0);

        if (transition.from) {
          if (transition.from.name == 'loading') return;

          this._trackPage(transition.from.name);
        } else {
          this._trackPage(null);
        }
      });
    },

    _trackPage(prevRoute) {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const currUrl = this.getWithDefault('currentURL', 'unknown');
        const currRoute = this.getWithDefault('currentRouteName', 'unknown');
        this.metrics.trackPage('GoogleAnalytics', {
          page: currUrl,
          title: currRoute
        }); // if (prevRoute && prevRoute !== currRoute) {
        //     this.metrics.trackPage('Pipz', { title: currUrl, referrer: prevRoute });
        // }
      });
    },

    setTitle(title) {
      this.get('headData').set('title', title);
    }

  });
  Router.map(function () {
    this.route('login-callback');
    this.route('login');
    this.route('logout');
    this.route('settings', {
      path: '/settings'
    }, function () {
      this.route('set-locale', {
        path: '/set-locale/:locale'
      });
    });
    this.route('dashboard', {
      path: '/dashboard'
    });
    this.route('accounts', {
      path: '/accounts'
    }, function () {
      this.route('impersonate', {
        path: '/:id/impersonate'
      });
    });
    this.route('account', {
      path: '/account'
    }, function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('companies', {
      path: '/companies'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('new', {
        path: '/new'
      });
      this.route('show', {
        path: '/:id'
      });
      this.route('edit', function () {
        this.route('index', {
          path: '/:companyId'
        });
        this.route('basic-info', {
          path: '/basic-info/:companyId'
        });
        this.route('certificate', {
          path: '/certificate/:companyId'
        });
        this.route('apikeys', {
          path: '/apikeys/:companyId'
        });
        this.route('environment', {
          path: '/environment/:companyId'
        });
        this.route('fiscal', {
          path: '/fiscal/:companyId'
        });
        this.route('taxes', {
          path: '/taxes/:companyId'
        });
      });
      this.route('service-invoices', {
        path: '/:companyId/service-invoices'
      }, function () {
        this.route('batch-issue');
        this.route('view', {
          path: '/:invoice_id'
        });
      });
    });
    this.route('companies-v2', {
      path: '/companies-v2'
    }, function () {
      this.route('index', {
        path: '/'
      });
      this.route('new', {
        path: '/new'
      });
      this.route('show', {
        path: '/:id'
      });
      this.route('edit', function () {
        this.route('index', {
          path: '/:id'
        });
        this.route('basic-info', {
          path: '/basic-info/:id'
        });
        this.route('certificate-v2', {
          path: '/certificate-v2/:id'
        });
        this.route('apikeys', {
          path: '/apikeys/:id'
        });
        this.route('nfe-dist', {
          path: '/nfe-dist/:companyId'
        });
        this.route('cte-dist', {
          path: '/dfe-dist/:companyId'
        });
        this.route('state-taxes', function () {
          this.route('index', {
            path: '/:id'
          });
          this.route('new', {
            path: '/new/:id'
          });
          this.route('edit', {
            path: '/:id/:stateTax_id/'
          });
          this.route('environment', {
            path: '/environment/:id/:stateTax_id'
          });
        });
      });
      this.route('product-invoices', function () {
        this.route('index', {
          path: '/:companyId'
        });
        this.route('upload-batch-issue', {
          path: '/upload-batch-issue/:companyId'
        });
        this.route('view', {
          path: '/:companyId/:invoiceId/:type'
        });
        this.route('events', {
          path: '/events/:companyId/:invoiceId'
        });
      });
      this.route('consumer-invoices', function () {
        this.route('index', {
          path: '/:companyId'
        }); // this.route('upload-batch-issue', { path: '/upload-batch-issue/:companyId' });
        // this.route('view', { path: '/:companyId/:invoiceId/:type' });
        // this.route('events', { path: '/events/:companyId/:invoiceId' });
      });
    });
    this.route('nfe', function () {
      this.route('upload-batch-query');
    });
    this.route('onboard', function () {
      this.route('service-invoice', function () {
        this.route('issue-step', {
          path: '/issue-step/:companyId'
        });
      });
    });
    this.route('consults', function () {
      this.route('legal-entity');
      this.route('natural-person');
      this.route('dfe-dist');
    });
    this.route('create-account');
    this.route('error');
    this.route('not-found', {
      path: '/*path'
    });
  });
  var _default = Router;
  _exports.default = _default;
});