define("nfe-customer-console-v2/models/address", ["exports", "@ember-data/model", "ember-cp-validations", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _model, _emberCpValidations, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    postalCode: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'não pode ser vazio'
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^\d{2}\.?\d{3}\-?\d{3}$/,
        message: 'CEP inválido'
      })]
    },
    street: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: 4,
        message: 'muito curto (minimo de 4 caracteres)'
      })]
    },
    number: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'muito curto (minimo de 1 caracteres)'
      }), (0, _emberCpValidations.validator)('length', {
        max: 10,
        message: 'muito longo (máximo de 10 caracteres)'
      })]
    },
    district: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'não pode ser vazio'
      }), (0, _emberCpValidations.validator)('length', {
        min: 1,
        message: 'muito curto (minimo de 1 caracteres)'
      })]
    },
    city: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    },
    state: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Não pode ser vazio'
      })]
    }
  });

  var _default = _fragment.default.extend(Validations, {
    prefectures: Ember.inject.service(),
    prefecturesAvailable: [],
    country: (0, _model.attr)('string'),
    postalCode: (0, _model.attr)('string'),
    street: (0, _model.attr)('string'),
    number: (0, _model.attr)('string'),
    additionalInformation: (0, _model.attr)('string'),
    district: (0, _model.attr)('string'),
    state: (0, _model.attr)('string'),
    city: (0, _attributes.fragment)('address-city'),
    company: (0, _model.belongsTo)('company'),

    serialize() {
      let model = this.getProperties(['country', 'postalCode', 'street', 'number', 'additionalInformation', 'district', 'state', 'city']);
      model.city = {
        code: this.get('city.code'),
        name: this.get('city.name')
      };

      if (this.get('state.code')) {
        model.state = this.get('state.code');
      }

      return model;
    }

  });

  _exports.default = _default;
});