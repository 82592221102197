define("nfe-customer-console-v2/components/list-api-keys/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zfEwbrHc",
    "block": "{\"symbols\":[\"key\",\"index\"],\"statements\":[[4,\"paper-list\",null,[[\"class\"],[\"layout-table-container\"]],{\"statements\":[[4,\"each\",[[24,[\"keys\"]]],null,{\"statements\":[[4,\"paper-item\",null,[[\"class\",\"data-test-accessKey\"],[\"md-3-line\",[23,2,[]]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"md-list-item-pad md-list-item-text\"],[8],[0,\"\\n        \"],[7,\"h5\",true],[10,\"class\",\"listKey-description\"],[8],[0,\"\\n          \"],[1,[23,1,[\"description\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"layout-row layout-align-start-center\"],[8],[0,\"\\n          \"],[7,\"code\",true],[11,\"id\",[28,\"concat\",[\"accessKey\",[23,2,[]]],null]],[10,\"class\",\"md-input flex-60 text-center\"],[8],[0,\"\\n            \"],[1,[23,1,[\"key\"]],false],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"flex-nogrow\"],[8],[0,\"\\n\"],[4,\"copy-button\",null,[[\"elemId\"],[[28,\"concat\",[\"accessKey\",[23,2,[]]],null]]],{\"statements\":[[0,\"                \"],[1,[28,\"paper-icon\",[\"assignment\"],[[\"size\"],[16]]],false],[1,[28,\"t\",[\"copy\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[22,\"paper-divider\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "nfe-customer-console-v2/components/list-api-keys/template.hbs"
    }
  });

  _exports.default = _default;
});